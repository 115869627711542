.local-post-navigation {
  $padding: 6em;
  .previous, .next {
    position: relative;
    padding: 0 $padding;
  }
  .previous {
    border-right: 1px solid black;
    padding-left: 10%;
  }
  .next {
    padding-right: 10%;
  }
  margin-bottom: $padding;
  a, a h4, a h5 {
    color: $dark-gray;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
  }
  .arrow {
    position: absolute;
    display: block;
    height: 100%;
    font-size: 50px;
    margin-top: -25px;
    top: 50%;
    vertical-align: middle;
  }
  .previous .arrow {
    left: 0;
  }
  .next .arrow {
    //left: 100%;
    right: 0;
  }
  .post-nav-thumb {
    width: 100%;
    margin-bottom: 1em;
  }
}
