/* Desktop Menu */

@font-face {
    font-family: "GT-Eesti 2r";
    font-weight: 200;
    src: url("../images/fonts/gt-eesti/_eot/GT-Eesti-Text-Light.eot");
    src: local("GT-Eesti 2r"),
    	url("../fonts/GT-Eesti-Text-Light.eot?#hfj") format("embedded-opentype"),
    	url("../fonts/GT-Eesti-Text-Light.woff") format("woff"),
    	url("../fonts/GT-Eesti-Text-Light.ttf") format("truetype");
}

#westelm-store-nav-desktop-trigger {
	border-radius:2px;
	position:fixed;
	left: 80px;
	top: 20px;
	padding: 5px 37px 5px 20px;
	border: 2px solid #d8d8d8;
	z-index: 10;
	text-decoration: none;
	font-size: 14px;
	letter-spacing: 0.5px;
	line-height: 23px;
	color: #006167;
	font-weight: bold;
	font-family: 'GT-Eesti 2r';
	letter-spacing: 1px;

	&:hover {
		border-color: #006167;
	}

	i {
		transition:all .2s ease-in-out;
		display:inline-block;
		width:12px;
		height:10px;
		background-repeat:no-repeat;
		background-position:center center;
		background-size:contain;
		position:absolute;
		right: 15px;
		top: 12px;
		background-image:url(../images/shop-toggle-arrow.svg);
	}

	&.close-shop-menu {
		border-color: #006167;
		i {
			top:12px;
			background-image:url(../images/shop-toggle-x.svg);
		}
	}
}

.westelm-store-nav-desktop {
	.westelm-store-nav-wrapper {
		display:none;
		position:fixed;
		left:0;
		top: 80px;
		z-index:10;
		width:100%;
		background-color:#fff;
		text-align:center;
		border-bottom: 3px solid #d9d9d9;

		ul {
			display:flex;
			justify-content: space-around;
			margin-left:0;
			margin-bottom:0;
			list-style-type:none;
			li {
				display:inline-block;
				margin-right:10px;

				&:last-of-type {
					margin-right:0;
				}

				a {
					color: #5f6062;
					font-size: 11px;
					font-weight: 700;
					letter-spacing: 1px;
					text-transform: uppercase;
					padding:3px 5px 5px;
					display:block;

					&:hover,
					&:active,
					&:focus {
						text-decoration: none;
						color:#006167;
						border-bottom:3px solid #006167;
						margin-bottom:-3px;
					}
				}

				&#sale a:hover {
					color:#af1a31;
					border-bottom:3px solid #af1a31;
				}
			}
		}
	}
}

/* Mobile Menu */

i.custom-right-chevron,
i.custom-down-chevron {
	transition: all 300ms ease-in-out;
	background-image:url(../images/caret-white.svg);
	transform-origin: center;
	margin-bottom:1px;
	width:15px;
	height:8px;
}

i.custom-right-chevron {
	transform: rotate(-90deg);
	margin-bottom: -2px;
	width:15px;
	height:15px;
}

#westelm-store-nav-mobile-trigger {
	color:#fff;
	background-color:#0a6166;
	display:block;
	width:100%;
	padding:10px 15px;
	font-size: 16px;
	letter-spacing: 1px;
	font-weight: 600;

	&:hover,
	&:focus {
		text-decoration: none;
	}

	i {
		display:inline-block;
		background-position:center center;
		background-size:100%;
		background-repeat:no-repeat;
		color:#fff;
		margin-right:5px;
	}
}

.westelm-store-nav-mobile ul {
	background-color:#ffffff;
	display:none;
	margin-left:0;
	list-style-type:none;
	margin-bottom:0;

	li {
		a {
			color: #363636;
			font-size: 12px;
			font-weight: 700;
			letter-spacing: .5px;
			padding:10px 15px;
			display:block;
			border-top:2px solid #ddd;

			&:hover {
				text-decoration: none;
			}

			img {
				border-radius:50%;
				height:35px;
				width:35px;
				margin-right:5px;
			}
		}

		&#sale a {
			border:none !important;
			background-color:#af1a31;
			color:#fff;
		}

		&:first-of-type a {
			border-top:none;
		}
	}
}

/* Change styles from theme */

#blog-menu-wrapper-trigger {
	color:#fff;
	background-color:#363636;
	display:block;
	width:100%;
	padding:10px 15px;
	font-size: 16px;
	letter-spacing: 1px;
	font-weight: 600;


	&:hover,
	&:focus {
		text-decoration: none;
	}

	i {
		display:inline-block;
		background-position:center center;
		background-size:100%;
		background-repeat:no-repeat;
		color:#fff;
		margin-right:5px;
	}
}


#wrapper-menu {
	padding-top:0 !important;
	padding-right:0 !important;
	padding-left:0 !important;
}

.blog-menu-wrapper {
	padding: 18px 15px;
}

@media (max-width:992px) {
	#westelm-store-nav-desktop-trigger,
	.westelm-store-nav-desktop ul {
		display:none !important;
	}
}

@media (min-width:991px) {
	#blog-menu-wrapper-trigger,
	#westelm-store-nav-mobile {
		display:none;
	}
}