.local-article-carousel {
  position: relative;
  $local-article-tile-content-height: 420px;
  @include carousel-background;
  &>.columns {
    max-width: $inner-columns-max-width;
    padding: 0;
  }
  .title {
    color: $primary-color;
    //font-size: rem-calc(26);
    font-weight: $font-weight-light;
    padding: 0 20px;
  }
  .article-tile {
    background-color: $tile-background-color;
    padding: 23px 27px;
    width: 100%;
    margin-left: 5%;
    margin-right: 5%;
    @include breakpoint(large down) {
      width: 75%;
      margin-left: 2.5%;
      margin-right: 2.5%;
    }
  }
  .article-thumbnail {
    overflow: hidden;
    max-height: $local-article-tile-content-height;
    //height: $local-article-tile-content-height;
    @include breakpoint(large down) {
      max-height: $local-article-tile-content-height/2;
      //height: $local-article-tile-content-height / 2;
      padding: 0;
    }
    padding: 0;
    .wp-post-image {
      height: auto;
      width: 100%;
    }
  }
  .article-content {
    overflow: hidden;
    .center {
      @include vertical-center;
      padding: 2em;
      text-align: center;
      @include breakpoint(large down) {
        transform: none;
        position: static;
        padding: 0;
        margin-top: 1em;
      }
    }
    p, h1, h2, h3, h4, h5 {
      color: $body-font-color;
      font-weight: $font-weight-light;
    }
    .article-title {
      text-transform: uppercase;
      font-weight: $font-weight-bold;
      font-size: rem-calc(22);
    }
    .article-excerpt {

    }
    .article-byline {
      color: $primary-color;
    }
    .article-author {
      text-transform: uppercase;
    }
  }
  .flickity-prev-next-button {
    background: transparent;
    @include breakpoint(large down) {
      display: none;
    }
    &.previous {
      left: -75px;
    }
    &.next {
      right: -75px;
    }
  }
}
