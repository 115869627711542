@mixin small-caps {
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  font-size: rem-calc(12);
}
#comments {
  form {
    label {
      @include small-caps;
    }
    .author, .email {
      display: inline;
      width: 30%;
    }
    .form-submit {
      display: inline;
      float: right;
      input {
        appearance: none;
        padding: 1em 5em;
        @include small-caps;
        border: none;
        color: white;
        background: #318086;
        border-radius: 4px;
      }
    }
  }
  .comment-notes, #reply-title, #email-notes, h2 {
    display: none;
  }
  .comment-list {
    list-style: none;
    margin: 2em 0;
    .comment {
      p, div {
        font-size: rem-calc(13);
      }
      .comment--author {
        font-size: rem-calc(11);
        text-transform: uppercase;
        font-weight: $font-weight-bold;
        margin: 0;
      }
      padding: 10px 0;
      border-bottom: 1px solid gray;
      &:first-child {
        border-top: 5px solid gray;
      }
    }
  }
}
