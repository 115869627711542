// Styles ported from old site
$margin: 15px;
$max-desktop: 1014px; // 964px;
$min-desktop: 1015px; // 965px;
$max-tablet: 759px;
$min-tablet: 760px;
$max-mobile: 319px;
$min-mobile: 320px;

body.archive, body.home {

  font-size: 16px;
  line-height: 24px;

  #wrapper-content {
    margin-top: 10px;
  }
  #row-categories {
    padding: $margin;
    .category img {
      width: auto;
      height: 20px;
    }
  }

  @media screen and (max-width: $max-desktop)
  {
    font-size: 18px;
    line-height: 28px;
  }

  @media screen and (max-width: $max-tablet)
  {
    line-height: 34px;
  }

  .local-home-banner {
    padding: 0;
    margin: 0;
    @include breakpoint(1024px up) {
      margin-top: $margin;
    }
    a {
      display: block;
    }
    img {
      display: none;
      width: 100%;
    }
    .local-home-banner--large {
      @include breakpoint(737px up) {
        display: block;
      }
    }
    .local-home-banner--medium {
      @include breakpoint(370px up) {
        display: block;
      }
      @include breakpoint(737px up) {
        display: none;
      }
    }
    .local-home-banner--small {
      @include breakpoint(369px down) {
        display: block;
      }
    }
  }

  article {
    //margin-bottom: 1em;
    a {
      color: #5f6062 !important;
    }
  }
}

////SIDE NAV
//body {
//  overflow-x: hidden;
//  overflow-y: auto;
//  #wrapper-menu {
//    left: -300px;
//    width: 300px;
//  }
//  & > nav, & > header, & > main, & > footer {
//    transition: all 0.2s ease-out;
//    transform: none;
//  }
//  &.nav-open {
//    overflow: hidden;
//    #wrapper-menu, & > header, & > main, & > footer {
//      transform: translateX(300px);
//    }
//    #wrapper-menu {
//      overflow-y: auto;
//    }
//  }
//  @include breakpoint(360px down) {
//    #wrapper-menu {
//      left: -250px;
//      width: 250px;
//    }
//    &.nav-open {
//      & > nav, & > header, & > main, & > footer {
//        transform: translateX(250px);
//      }
//    }
//  }
//}
//
////SIDE NAV 2 - LOCAL MENU
//body {
//  $local-nav-width: 175px;
//  #nav-local {
//    right: -$local-nav-width;
//    width: $local-nav-width;
//  }
//  &.local-nav-open {
//    @include breakpoint($breakpoint-header-collapse down) {
//      #nav-local, & > header, & > main, & > footer {
//        transform: translateX(-$local-nav-width);
//      }
//    }
//  }
//}

.off-canvas-wrapper, .off-canvas-wrapper-inner {
  min-height: 100%;
}
.off-canvas-wrapper {
  background: #ebebeb;
}
.off-canvas-content {
  background: white;
}
.off-canvas {
  background: none;
  overflow-y: visible;
}
body > nav {
  font-size: 10px;
  font-weight: $font-weight-bold;
  letter-spacing: 0.5px;
  line-height: 10px;
  text-transform: uppercase;
  color: $dark-gray;
}

#nav-local {
  //z-index: 255;
  padding-top: 2em;
  li {
    margin: 0;
    line-height: 400%;
  }
  a {
    color: inherit;
    &:hover {
      color: $primary-color;
    }
  }
}

#wrapper-menu {
  padding: 18px $margin 0 $margin;

  form {
    .input-group {
      span, input {
        font-weight: normal;
        background: white;
        border: none;
        box-shadow: none;
        appearance: none;
        border-radius: 0 !important;
      }
    }
    .input-group-label {
      font-size: 20px;
    }
    input {
      font-size: rem-calc(14);
    }
  }


  #list-menu-categories {
    margin: 0;
    padding: 0;
    margin-bottom: $margin;
    padding-bottom: $margin;
      // @media screen and (max-width: $max-desktop)
      // {
      border-bottom: 1px solid #FFF;
      // }
    li.category {
      margin: 0 0 18px 8px;
      list-style: none;

      a {
        display: block;
        text-indent: 0;
        margin-left: 0;
        color: inherit;
        font-weight: $font-weight-bold;
        &:hover {
          color: $tertiary-color;
        }
      }
      @include local-pages {
        #wrapper-menu #list-menu-categories li.category a:hover {
          color: $primary-color;
        }
      }
      img {
        margin: 0 $margin/2 0 0;
        width: 20px;
        height: auto;
        vertical-align: middle;
      }
    }
  }

  .global-social-links {
    display: none;
    @include breakpoint($breakpoint-header-collapse down) {
      display: block;
      li {
        margin: 0;
        margin-right: 4px;
      }
    }
  }
}


.subheader {
  color: #5f6062;
  //font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: .5px;
  text-transform: uppercase;
}

.westelm-classic-article {
  border-left: $margin/2 solid white;
  border-right: $margin/2 solid white;
  background: #f0ebe6 url(../images/article-border.png) repeat-x bottom left;
  margin-bottom: $margin;
  padding: 0;

  h1 {
    margin: 0 $margin $margin;
  }
  p {
    margin: 0 $margin $margin;
  }

  .subheader {
    a {
      // border-bottom: 1px solid #e0e0e0;
      color: inherit;
      display: inline-block;
      line-height: 1;
      text-decoration: underline;
    }
  }

  .header {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAABCAIAAAANelpWAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6Rjc5ODQ3QTg1MTQ5MTFFM0E3RDg4Rjc3OEU0ODZCQTQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NTMzMUI5MTQ1MTRGMTFFM0E3RDg4Rjc3OEU0ODZCQTQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpGNzk4NDdBNjUxNDkxMUUzQTdEODhGNzc4RTQ4NkJBNCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpGNzk4NDdBNzUxNDkxMUUzQTdEODhGNzc4RTQ4NkJBNCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pue5rioAAAARSURBVHjaYvT28mEYagAgwACevADjASGXXgAAAABJRU5ErkJggg==) no-repeat bottom left;
    color: #5f6062;
    //font-family: "Gotham SSm A", sans-serif;
    font-size: rem-calc(20);
    font-weight: $font-weight-bold;
    line-height: 100%;
    padding-bottom: 20px;
    text-transform: uppercase;
    a {
      border-bottom: 1px solid #e0e0e0;
      color: #5f6062;
      display: inline-block;
      text-decoration: none;
    }
  }
  .img-mask {
    display: block;
    overflow: hidden;
    margin-bottom: $margin;
  }

  .wrapper-img {
    display: block;
    img {
      height: auto;
      width: 100%;
    }
  }
}
