body.single-post, body.page:not(.page-template)
{

  .post, .page
  {
    @include standard-section-spacing;
    .standard-columns {
      max-width: 930px;
    }
    .banner {
      position: relative;
      height: 400px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-blend-mode: multiply;
      .entry-header {
        @include vertical-center;
        color: white;
      }
      p {
        margin: 0;
      }
      .category {
        a {
          color: white;
          &:hover, &:active {
            color: inherit;
          }
        }
        img {
          filter: brightness(10);
          transform: translateZ(0);
        }
      }
      .updated, .author {
        a {
          color: inherit;
        }
        font-weight: $font-weight-light;
      }
      .entry-title, .author {
        text-transform: uppercase;
      }
      .entry-title {
        font-size: rem-calc(32);
        font-weight: $font-weight-bold;
		letter-spacing: 1.5px;
      }
    }
    .entry-content {
      margin-top: rem-calc(25);
      img, iframe {
        display: block;
        width: 100%;
        margin: 2em auto;
      }
    }
    .entry-author {
      margin: 4em 0;
    }
  }

  .entry-related-posts {
    @include standard-section-spacing;

    .entry-related-posts--title {
      color: $dark-gray;
      font-weight: $font-weight-bold;
      font-size: rem-calc(20);
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 2em;
    }
  }
  .page .banner {
    background-image: url(../images/default-header-bg.jpg);
  }
}

body.single-maker {
  p {
    @include font-gotham;
    font-size: $global-font-size;
    line-height: $global-lineheight;
  }

  article.type-maker {
    max-width: $inner-columns-max-width;
    background: $tile-background-color;
    margin-top: -140px; //overlap the header texture fill
    padding: $tile-padding;
    @include standard-section-spacing;
    .columns>* {
      margin-bottom: 1rem;
    }
    .entry-title {
      @include font-gotham-bold;
      text-transform: uppercase;
      font-size: rem-calc(36);
      margin-bottom: rem-calc(30);
    }
    .entry-content {
      a {
        color: $tertiary-color;
      }
    }
    .local-maker--social {
      margin-bottom: rem-calc(35);
      a {
        display: inline-block;
        width: 36px;
        height: 36px;
        background-color: $secondary-color;
        border-radius: 50%;
        margin-right: 5px;
        text-align: center;
        padding-top: 8px;
      }
      i {
        color: $tile-background-color;
        font-size: 20px;
      }
    }
    .profile-field {
      color: $primary-color;
    }
    .columns.interview {
      margin-top: 2em;
      @include breakpoint(medium up) {
        columns: 2;
        column-fill: balance;
      }
      p:nth-child(odd) {
        margin-bottom: 0.5em;
      }
      strong {
        font-weight: 500;
        font-size: rem-calc(22);
        text-transform: uppercase;
      }
    }
    .columns.photo {
      img {
        margin: 0;
        padding: 0;
      }
      a {
        display: inline-block;
        background: $primary-color;
        color: white;
        padding: 9px 13px;
        position: relative;
        height: 30px;
        top: -30px;
        text-transform: uppercase;
        font-weight: $font-weight-light;
        font-size: 11px;
        i {
          vertical-align: middle;
          margin-right: 4px;
          font-size: 16px;
        }
      }
    }
    .columns.video {
      padding-top: 2em;
      //iframe {
      //  width: 100%;
      //}
    }
  }
}
