.local-maker-grid {
  position: relative;
  background: $tile-background-color;
  max-width: $inner-columns-max-width;
  background: $tile-background-color;
  padding: $tile-padding;

  .title {
    //font-size: rem-calc(26);
    font-weight: $font-weight-light;
    color: $primary-color;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: (-26px - 14px);
  }

  .maker-tile {
    margin-bottom: 25px;
    img {
      width: 100%;
      margin-bottom: 1em;
    }
    .maker-name {
      font-weight: $font-weight-light;
      text-transform: uppercase;
    }
    .maker-excerpt {
      color: $body-font-color;
    }
    p {
      margin: 0;
    }
  }

  .all-makers-tile {
    position: relative;
    .square {
      border: 6px solid $primary-color;
      padding-bottom: 95%;
    }
    a {
      position: absolute;
      width: 100%;
      padding: 0 20%;
      line-height: 200%;
      letter-spacing: 0.1rem;
      font-size: rem-calc(25);
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      text-align: center;
      text-transform: uppercase;
      font-weight: $font-weight-light;
    }
  }

  .quote-tile {
    width: 50%;
    @include breakpoint(medium down) {
      width: 100%;
      margin-bottom: 25px;
    }
    border-top: 1px solid #aaa;
    border-bottom: 1px solid #aaa;
    padding-top: 1em;
    text-align: center;
    font-size: rem-calc(22);
    position: relative;
    .centered {
      @include vertical-center;
    }
    p {
      font-weight: $font-weight-bold;
      line-height: 100%;
    }
    .hanging-quote {
      font-size: rem-calc(30);
      display: block;
      line-height: 0;
      &:first-child {
        padding-top: 10px;
        margin-bottom: 10px;
      }
      &:last-child {
        margin-top: 20px;
      }
    }
    .attribution {
      font-size: rem-calc(16);
      font-weight: $font-weight-light;
    }
  }
  .column-saver {
    @include breakpoint(medium down) {
      display: none;
    }
  }
  .content-row {
    overflow: hidden;
    //max-height: 5000px;
    //transition: max-height 0.5s ease-out;
    &.single-row {
      text-align: center;
      .maker-tile {
        float: none !important;
        display: inline-block;
      }
    }
  }
  .expander-row {
    cursor: pointer;
    padding: 25px 0 0 0;
    text-align: center;
    .columns {
      padding: 0;
    }
    .icon {
      font-size: 400%;
    }
    .icon.expand {
      display: none;
    }
  }
  &.collapsed {
    .expander-row {
      display: block;
    }
    .icon.collapse {
      display: none;
    }
    .icon.expand {
      display: inline-block;
    }
  }
}

body.page-template-template-local-hub {
  .local-maker-grid {
    margin-bottom: 100px;
  }
}

body.post-type-archive-maker {
  .local-maker-grid {
    margin-top: 100px;
  }
}
