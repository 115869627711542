//main {
//  margin-top: $header-height;
//}

.local-header {
  // override sticky values that are not helpful at all
  top: 0 !important;
  left: 0 !important;
  margin-top: 0 !important;
  width: 100% !important;
  max-width: none !important;
  position: relative;

  @at-root .is-off-canvas-open .local-header {
    position: absolute !important;
  }

  height: $header-height;
  background: white;
  z-index: 105;
  border-bottom: 3px solid #d9d9d9;
  ul {
    background: transparent;
  }
  #nav-toggle {
    position: absolute;
    line-height: 0;
    font-size: 30px;
    margin-top: -13px;
    top: 50%;
    left: 20px;
    color: $dark-gray;

    i.custom-bars {
      transition:all .3s ease-in-out;
      display:block;
      width:25px;
      height:25px;
      background-image:url(../images/menu-bars.svg);
      background-repeat:no-repeat;
      background-size:100%;
      background-position:center center;
    }
  }
  #local-nav-toggle {
    //@include vertical-center;
    position: absolute;
    margin-top: -20px;
    top: 50%;
    //position: fixed;
    //top: 18px;
    display: none;
    line-height: 0;
    height: 40px;
    left: 270px;
    @media (max-width:992px) {
      left: 65px;
    }
    @at-root body.page-template-template-local-home {
      .local-header #local-nav-toggle {
        display: block;
        cursor: default;
      }
    }
    @at-root body.single-maker, body.page-template-template-local-home, body.page-template-template-local-hub, body.category-local, body.category-local-article, body.category-local-article-featured {
      .local-header #local-nav-toggle {
        display: block;
      }
    }
    @at-root body.page-template-template-local-home {
      @include breakpoint($breakpoint-header-collapse down) {
        .local-header #local-nav-toggle {
          display: block;
          cursor: pointer;
        }
      }
    }
    @include breakpoint($breakpoint-header-collapse down) {
      left: auto;
      right: 10px;
    }
  }
  .local-header--social {
    @include vertical-center;
    text-align: right;
    line-height: 0;
    right: 20px;
    color: $dark-gray;
    @include breakpoint($breakpoint-header-collapse down) {
      display: none;
    }
  }
  .local-header--shop-west-elm {
    display: block;
    line-height: 100%;
    margin: 5px 0 0 0;
    color: inherit;
    text-decoration: underline;
  }
  .local-header--title {
    line-height: 0;
    margin-top: 15px;
    width: 100%;
    text-align: center;
    #front-main-logo {
      display: inline-block;
      background: url(../images/front-main-logo.svg) center center no-repeat;
      width: 245px;
      height: 25px;
    }
    p, a {
      margin: 5px 0 0 0;
      padding: 0;
      color: $dark-gray;
      font-size: 10px;
      line-height: 10px;
      font-weight:bold;

      span {
        font-weight:normal;
      }
    }
    .key-color {
      color: $tertiary-color;
    }
    @include breakpoint(400px down) {
      margin-top: 20px;
      #front-main-logo {
        width: 195px;
      }
      p, a {
        font-size: 10px;
        line-height: 10px;
      }
    }
  }
}

.is-open-left {
  #nav-toggle i.custom-bars {
    background-image:url(../images/menu-x.svg);
  }
}

#front-main-logo--plus {
  fill: $tertiary-color;
}

@include local-pages {
  .local-header {
    border-color: $primary-color;
    .key-color {
      color: $primary-color;
    }
  }
  #front-main-logo--plus {
    fill: $primary-color;
  }
}

.local-hub-header {
  @include standard-section-spacing;
  position: relative;
  background-color: $tile-background-color;
  background-image: url(../images/hub-header-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 0;
  width: 100%;
  //height: 400px;
  text-align: center;
  padding: 72px 0;
  .local-hub-header--block {
    margin: 0 auto;
    display: inline-block;
    background-color: rgba(255, 255, 255, 0.94);
    padding: rem-calc(40);
  }
  .local-hub-header--copy {
    margin: 0 auto;
    max-width: 720px;
    font-size: rem-calc(26);
    font-weight: $font-weight-light;
    h1, h2, h3, h4, h5 {
      color: $primary-color;
      text-transform: uppercase;
      font-size: rem-calc(44);
      font-weight: $font-weight-bold;
    }
    @include breakpoint(small down) {
      h1, h2, h3, h4, h5 {
        font-size: rem-calc(35);
      }
      font-size: rem-calc(20);
      line-height: rem-calc(30);
    }
  }
  .local-hub-header--stores {
    margin-top: 50px;
    width: 100%;
    text-align: center;
    div {
      vertical-align: top;
      display: inline-block;
      width: 14em;
    }
    a {
      //color: $body-font-color;
      text-decoration: underline;
    }
  }
  .local-hub-header--buttons a {
    z-index: 20;
    position: absolute;
    width: 160px;
    font-size: rem-calc(16);
    color: white;
    text-transform: uppercase;
    top: 20px;
    left: 20px;
    text-align: center;
    img, i {
      font-size: 250%;
      display: inline-block;
      width: 75%;
      height: auto;
      padding-bottom: 10px;
    }
  }
  .local-hub-header--home {
    display: block;
  }
  .local-hub-header--back {
    display: none;
  }
}

header.local-maker-fill {
  width: 100%;
  height: 400px;
  @include breakpoint (medium down) {
    height: 250px;
  }
  background: gray url(../images/default-header-bg.jpg) no-repeat 50% 0;
  background-size: cover;
}

.global-social-links {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
    display: inline;
    margin-left: 10px;
    a {
      color: $dark-gray;
    }
  }
  $icon-size: 20px;
  img {
    vertical-align: middle;
    width: auto;
    height: ($icon-size + 2);
    margin-top: -4px;
  }
  a {
    line-height: 0;
    font-size: $icon-size;
  }
}
