$map-header-breakpoint: 738px;

.header--map--container {
  width: 90%;
  max-width: 1225px;
  height: auto;
  margin: 0 auto;
  position: relative;

  .header--map {
    position: relative;
    display: inline-block;
    width: 77%;
    height: auto;
    left: 23%;

    @media (max-width: $map-header-breakpoint) {
      display: inline-block;
      position: absolute;
      width: 55%;
      left: 45%;
      margin-top: 5%;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .header--copy {
    pointer-events: none;
    position: absolute;
    text-align: center;
    width: 27.4%;
    display: inline-block;
    left: 0%;
    bottom: 10%;

    @media (max-width: $map-header-breakpoint) {
      display: inline-block;
      text-align: none;
      position: relative;
      width: 100%;
      left: 0%;
      top: 0%;
    }

    img {
      width: 100%;
      height: auto;

      @media (max-width: $map-header-breakpoint) {
        display: inline-block;
        position: static;
        margin: 0 auto;
        max-width: 336px;
      }
    }

    .header--logo {
      margin-bottom: 6%;
      max-width: 163px;
      width: 48.5%;

      @media (max-width: $map-header-breakpoint) {
        position: relative;
        display: block;
        width: 32%;
        max-width: 175px;
        margin-top: 8%;
        left: -25%;
      }

    }

    .header--scopy {
      @media (max-width: $map-header-breakpoint) {
        position: relative;
        width: 100%;
        margin-top: 8%;
      }
    }

    .mobile {
      display: none;
      max-width: 570px;
      @media (max-width: $map-header-breakpoint) {
        display: block;
      }
    }

    .fs {
      display: block;
      @media (max-width: $map-header-breakpoint) {
        display: none;
      }
    }

  }
}

.header--map {
  .pin {
    display: block;
    position: absolute;

    width: 58px;
    height: 75px;
    margin-left: -28px;
    margin-top: -65px;

    @include breakpoint(medium down) {
      width: 30px;
      height: 38px;
      margin-left: -15px;
      margin-top: -30px;
    }
    @include breakpoint($map-header-breakpoint down) {
      display: none;
    }
    background: url(../images/map-pin.png) no-repeat 0 0;
    background-size: 100% 100%;
    animation: 0.5s drop-pin;

    @keyframes drop-pin {
      from {
        transform: translateY(-800px);
      }
      to {
        transform: translateY(0);
      }
    }

    transition: transform 0.25s ease-out;
    &:hover {
      transform: translateY(-5px);
    }
  }
}


.header--map-embedded-container {
  position: absolute;
  top: $header-height;
  left: 0;
  width: 100%;
  height: 520px;
  max-height: 100vh;
}
header .header--map-embedded-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-height: none;
}

.header--map-embedded-container {
  background-color: rgba(52, 67, 85, 0.8);
  text-align: center;
  .header--map {
    width: 90%;
    max-width: 800px;
    height: auto;
    transition: all 0.5s ease-out;
    @include absolute-center;
  }
  opacity: 0;
  display: none;

  @at-root body.local-nav-open & {
    @include breakpoint ($breakpoint-header-collapse up) {
      display: block;
      opacity: 1;
    }
  }
}
