.local-find-in-store {
  background-color: $tile-background-color;
  background-image: url(../images/hub-header-bg.jpg);
  background-size: cover;
  padding: rem-calc(40) 0;
  text-align: center;
  .box {
    display: inline-block;
    background-color: rgba(255, 255, 255, 0.94);
    padding: rem-calc(40) rem-calc(80);
  }
  .store {
    text-align: center;
    vertical-align: top;
    display: inline-block;
    width: 14em;
    a {
      text-decoration: underline;
    }
  }
  .title {
    font-weight: $font-weight-bold;
    margin-bottom: 2em;
    text-align: center;
    text-transform: uppercase;
    color: $body-font-color;
  }
}
