.local-shop-the-look {
  @include carousel-background;
  border: none;
  background: $tile-background-color;
  width: 100%;
  h1, h2, h3 {
    margin: 0 auto;
    text-align: center;
    color: $dark-gray;
    font-size: rem-calc(20);
    margin-bottom: 2em;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
  }
  .carousel {
    width: 70%;
    max-width: 1200px;
    margin: 0 auto;
    @include breakpoint (medium down){
      width: 60%;
    }
  }
  .flickity-prev-next-button {
    &:hover, &:down {
      opacity: 1.0;
      background: transparent;
    }
    opacity: 1.0;
    background: transparent;
    top: 40%;
  }
  .flickity-prev-next-button.previous {
    left: -40px;
    font-size: 40px;    
    .arrow{
      fill: $dark-gray;
    }
  }
  .flickity-prev-next-button.next {
    right: -40px;
    font-size: 40px;
    .arrow{
      fill: $dark-gray;
    }
  }
  .product {
    text-align: center;
    width: calc(25% - 24px);
    height: calc(250px + 18px) ;
    color: $body-font-color;
    font-size: rem-calc(18);
    vertical-align: top;
    display: inline-block;
    margin-left: 12px;
    margin-right: 12px;
    @media screen and (max-width: 1024px){
      width: 100%;
    }
    a {
      color: inherit;
    }
    img {
      width: 190px;
      margin-bottom: 1em;
      max-height: 250px;
      display: inline-block;
    }
  }
}
