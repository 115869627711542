.author-bio {
  max-width: 930px;
  position: relative;
  .author-bio--image-column {
    @include vertical-center;
  }
  .author-bio--text-column {
    padding-right: 1em;
  }
  img.avatar {
    display: block;
    margin: 0 auto;
    width: 110px;
    max-width: none;
    height: auto;
    border-radius: 50%;
  }
  p {
    font-weight: $font-weight-light;
    font-size: rem-calc(13);
    margin: 0.2em 0;
    line-height: 1.2em;
  }
  .author-bio--name {
    text-transform: uppercase;
  }
  .author-bio--label {
    text-transform: lowercase;
  }
}


.local-footer {
  position: relative;
  height: 145px;
  background-color: $primary-color;
  .left, .right {
    @include vertical-center;
  }
  .logo {
    height: 50px;
    width: auto;
    vertical-align: middle;
    margin-top: -10px;
  }
  .links {
    min-height: 50px;
    border-left: 3px solid white;
    padding-left: 20px;
    margin-left: 20px;
    display: inline-block;
    vertical-align: middle;
    li, a {
      color: white;
    }
    li {
      font-weight: $font-weight-light;
      list-style: none;
      //margin: 0;
      //padding: 0;
      //text-indent: 0;
      line-height: 120%;
    }
  }
  @include breakpoint(medium down) {
    height: auto;
    width: 100%;
    padding: 2em 0;
    .standard-columns {
      width: 100%;
    }
    .left, .right {
      position: static;
      margin: 0;
      transform: none;
      top: 0;
    }
    .logo, .links {
      padding: 0;
      margin: 0;
      display: block;
    }
    .links {
      border: none;
      margin: 1em 0 0 0;
    }
  }
}
