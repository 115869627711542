body.page-template-template-local-home {
  .local-home-header {
    @include standard-section-spacing;
    padding: 30px 0;
    //position: relative;
    background-color: #112330;
    color: white;
    overflow: hidden;
    background: url(../images/local-home-bg.jpg) no-repeat 0 0;
    background-size: cover;
    background-position: 50% 50%;
  }

  .home--hero {
    position: relative;
    height: 400px;
    background-color: $primary-color;
    background: url(../images/local-home-video-bg.jpg) no-repeat 0 0;
    background-size: cover;
    .center {
      @include vertical-center;
      text-align: center;
      @include breakpoint(medium up) {
        width: 140%;
        margin-left: -20%;
      }
      p {
        color: black;
        font-weight: 200;
        line-height: 110%;
      }
      a {
        .fa {
          margin-right: 0.5em;
        }
        color: white;
      }
    }
    p {
      font-size: rem-calc(32);
      @include breakpoint(small down) {
        padding: 0 20px;
        font-size: rem-calc(24);
      }
    }
    a {
      font-size: 75%;
      //font-size: rem-calc(24);
    }
  }
}
