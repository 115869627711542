* {
  box-sizing: border-box;
}

html, body, main {
  width: 100%;
}

@mixin font-gotham {
  font-family: $font-family-gotham;
  font-weight: $font-weight-medium;
}

@mixin font-gotham-light {
  font-family: $font-family-gotham;
  font-weight: $font-weight-light;
}

@mixin font-gotham-bold {
  font-family: $font-family-gotham;
  font-weight: $font-weight-bold;
}

//Gotham SSm A/B 2,3,4,5,7,800

@mixin local-pages {
  @at-root {
    body.single-maker, body.page-template-template-local-hub, body.page-template-template-local-home, body.category-local-article, body.category-local-article-featured, body.category-toolkit, body.post-type-archive-maker {
      @content;
    }
  }
}

.primary-color {
  color: $primary-color;
}

a {
  color: $tertiary-color;
}

@include local-pages {
  a {
    color: $primary-color;
  }
}

.uppercase {
  text-transform: uppercase;
}

.standard-columns {
  @extend .columns;
  @extend .large-10;
  @extend .small-12;
  @extend .small-centered;
  max-width: 1200px;
}

.alternate-background-row {
  padding: 4em 0;
  background-color: $tile-background-color;
}

.local-section-title {
  //font-size: rem-calc(26);
  font-weight: $font-weight-light;
  color: $primary-color;
  margin-bottom: 1em;
  //position: absolute;
  //top: 0;
  //left: 0;
  //margin-top: (-26px - 14px);
}

.list-unstyled {
  list-style: none;
}

$section-spacing-large: 100px;
$section-spacing-small: 50px;
@mixin standard-section-spacing {
  margin-bottom: $section-spacing-small;
  @include breakpoint(medium up) {
    margin-bottom: $section-spacing-large;
  }
}
section {
  @include standard-section-spacing;
}

hr.brushstroke {
  display: block;
  width: 160px;
  height: 10px;
  margin: 0 auto;
  border: none;
  background: transparent url(../images/hr-brushstroke.png) no-repeat top left;
  background-size: 100% 100%;
  &.white {
    width: 330px;
    height: 4px;
    background-image: url(../images/hr-brushstroke-white.png);
  }
}

.alm-ajax .alm-reveal {
  display: none;
}

//This is a bad idea
@media only screen {
  a:focus {
    outline: none;
  }
}
