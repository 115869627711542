body.category-toolkit {
  .maker-toolkit-header {
    @include standard-section-spacing;
    padding: 10px 20px 5px 20px;
    min-height: 130px;
    width: 100%;
    background: #febc59;
    background-image: url(../images/toolkit-header-bg.png);
    background-repeat: repeat;
    background-position: 50% 50%;

    .content {
      width: 100%;
      color: white;
      text-align: center;
      h1 {
        font-size: rem-calc(33);
        text-transform: uppercase;
        font-weight: $font-weight-bold;
        letter-spacing: 0.2em;
        margin: 0;
      }
      h2 {
        font-size: rem-calc(18);
        margin: 0;
      }
      hr {
        margin: 10px auto;
      }
    }
  }
}
