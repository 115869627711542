body.category-holiday {
  .local-header {
    border-bottom: 3px solid #006167;
  }
  .holiday-header {
    @include standard-section-spacing;
    padding: 20px 20px 5px 20px;
    min-height: 130px;
    width: 100%;
    background: #006167;
    background-image: url(../images/make_it_merry_header.jpg);
    background-repeat: repeat;
    background-position: top;

    .content {
      width: 100%;
      color: #006167;
      text-align: center;
      h1 {
        font-size: rem-calc(33pt);
        text-transform: uppercase;
        font-weight: $font-weight-bold;
        letter-spacing: 0.2em;
      }
      h2 {
        font-size: rem-calc(16pt);
        margin: 0;
      }
    }
  }
  .local-article-carousel {
    .title {
      color: #006167;
    }
    .article-byline {
      color: #006167;
    }
  }
}
