@mixin carousel-background {
  background-color: gray;
  background-image: url(../images/products-row-bg.gif);
  background-repeat: repeat;
  border: 1px solid #9d9d9d;
  border-left: none;
  border-right: none;
  padding: 5rem 0;
}

.local-product-carousel {
  @include carousel-background;

  .carousel {
    text-align: center;
  }
  .columns {
    max-width: $inner-columns-max-width;
  }
  .title {
    color: $primary-color;
    //font-size: rem-calc(26);
    font-weight: $font-weight-light;
    padding: 0;
  }
  .product {
    display: inline-block;
    vertical-align: top;
    width: 420px;
    height: 550px;
    margin-right: 25px;
    background-color: $tile-background-color;
    text-align: center;
    @include breakpoint (small down) {
      width: 200px;
      height: 300px;
      margin-right: 15px;
    }
    a {
      padding: 90px 0 0 0;
      @include breakpoint (small down) {
        padding-top: 10px;
      }
      display: block;
      width: 100%;
      height: 100%;
      position: relative;
      text-align: center;
    }
    img {
      display: block;
      margin: 0 auto;
      width: 250px;
      height: auto;
      @include breakpoint (small down) {
        width: 180px;
      }
    }
    .title, .maker {
      width: 100%;
      position: absolute;
      font-size: rem-calc(16);
    }
    .stores-only {
      margin: 0 auto;
      width: 250px;
      @include breakpoint (small down) {
        width: 180px;
      }
      position: relative;
      //z-index: 10;
      text-align: left;
      height: 0;
      overflow: visible;
      span {
        background: black;
        color: white;
        font-size: rem-calc(12);
        @include breakpoint (small down) {
          font-size: rem-calc(10);
        }
        padding: 0.5em;
        line-height: 2rem;
      }
    }
    .brushstroke {
      position: absolute;
      left: 50%;
      margin-left: -80px;
      top: 368px;
      @include breakpoint (small down) {
        top: 210px;
      }
    }
    .title {
      top: 405px;
      @include breakpoint (small down) {
        top: 235px;
        font-size: 70%;
      }
      font-weight: $font-weight-bold;
      color: black;
      text-transform: uppercase;
    }
    .maker {
      top: 445px;
      @include breakpoint (small down) {
        top: 265px;
        font-size: 70%;
      }
      font-weight: $font-weight-light;
      color: $primary-color;
    }
  }
}

