.local-recently-added {
  $tile-padding: 55px;

  .local-section-title {
    margin-left: -10px;
  }
  .tile {
    padding: $tile-padding;
    background: $tile-background-color;
  }
  .col {
    display: inline-block;
    float: left;
    height: 100%;
    margin: 0;
  }
  .local-recently-added--fullheight {
    width: 30%;
    article {
      font-size: rem-calc(16);
      &>* {
        margin-bottom: 20px;
      }
      text-align: center;
      img {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
      }
      .post-title {
        width: 100%;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
        margin-bottom: 10px;
      }
    }
  }
  .local-recently-added--4up {
    width: 70%;
    padding-left: $tile-padding;
    article {
      font-size: rem-calc(14);
      float: left;
      width: 50%;
      padding-right: 24px;
      p {
        margin-top: 0.5rem;
      }
    }
  }
  @include breakpoint (small down) {
    .local-recently-added--fullheight, .local-recently-added--4up {
      display: block;
      float: none;
      max-width: 100%;
      width: 300px;
      margin: 0 auto;
      padding: 0;
    }
    .local-recently-added--4up {
      a:nth-child(n+3) {
        display: none;
      }

      article {
        width: 100%;
        padding: 0;
      }
    }
  }
  .post-title {
    color: $body-font-color;
  }
  .post-byline {
    color: $primary-color;
    font-weight: $font-weight-light;
    padding: 0;
  }


}
